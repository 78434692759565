import Vue from 'vue';
import Vuex from 'vuex';
import authorized from './authorized';
import area from './area';
import brand from './brand';
import branch from './branch';
import bankAccount from './bank-account';
import bankTransaction from './bank-transaction';
import category from './category';
import cheque from './cheque';
import customer from './customer';
import companyProfile from './company-profile';
import cashTransaction from './cash-transction';
import damage from './damage';
import dashboard from './dashboard';
import department from './department';
import designation from './designation';
import employee from './employee';
import expense from './expense';
import layout from './layout';
import month from './month';
import product from './product';
import purchase from './purchase';
import unit from './unit';
import snackbar from './snackbar';
import sale from './sale';
import supplier from './supplier';
import salaryPayment from './salary-payment';
import user from './user';
import languages from '../languages';
import countries from '../countries';
import axios from 'axios'
import _ from 'lodash'

Vue.use(Vuex);

const Store =  new Vuex.Store({
    state: () => {
        return {
            languages: [],
            loadingLanguage: false,
            selectedLanguage: localStorage.getItem('lang') || 'en',

            host: process.env.NODE_ENV === 'production' ? 'https://shareef-artistry.zealtechpos.com/api' : 'http://127.0.0.1:8005/api',
            hostName: process.env.NODE_ENV === 'production' ? 'https://shareef-artistry.zealtechpos.com/' : 'http://127.0.0.1:8005/',

            countries: countries,
        }
    },

    getters: {
        countries(state) {
            return state.countries;
        },
        languages(state) {
            return state.languages;
        },
        loadingLanguage(state) {
            return state.loadingLanguage;
        },

    },

    mutations: {
        setLanguages(state, languages) {
            state.languages = languages;
        },
        setLoadingLanguage(state, loading) {
            state.loadingLanguage = loading;
        },
    },

    actions: {
        async getLanguages(context) {
            context.commit('setLoadingLanguage', true)
            // console.log('loading', this.getters['loadingLanguage']);
            let languages = await axios.get(`${this.state.host}/get-language`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => res.data)
            .catch(error => {})
            context.commit('setLoadingLanguage', false)
            let modify = _.map(languages, (item, index) => {
                item.sl = index + 1;
                return item;
            })
            context.commit('setLanguages', modify);
            // console.log(this.getters['languages']);
        },
        async updateLang(context) {
            context.commit('setLoadingLanguage', true)
            // console.log(this.getters['languages']);
            let token = localStorage.getItem('jwtToken')
            let langs = this.getters['languages'] || [];
            await axios.post(`${this.state.host}/update-language`, {
                langs: langs,
            }, {
                headers: {
                    'Authorization': token
                }
            }).then(res => res.data)
            .then(result => {}).catch(err => {})
            context.commit('setLoadingLanguage', false)
            this.dispatch('getLanguages')
        },
    },

    modules: {
        authorized,
        area, 
        brand, 
        branch,
        bankAccount,
        bankTransaction,
        category, 
        cheque,
        customer,
        companyProfile, 
        cashTransaction,
        damage,
        dashboard,
        department,
        designation,
        employee,
        expense,
        layout,
        month,
        product,
        purchase,
        unit, 
        user, 
        sale,
        supplier, 
        salaryPayment,
        snackbar, 
    }
})

Store.saveTranslation = function () {
    let token = localStorage.getItem('jwtToken')
    let langs = JSON.parse(localStorage.getItem('langs')) || [];
    axios.post(`http://127.0.0.1:8005/api/add-language`, {
        langs: langs,
    }, {
        headers: {
            'Authorization': token
        }
    }).then(res => res.data)
    .then(result => {
        console.log(result);
        localStorage.setItem('langs', JSON.stringify([]))
    })
}

Store.translate = function(text, lan_from='en') {
    let store_languages = this.getters['languages'];
    let found = _.find(store_languages, item => {
        return item[lan_from].toLowerCase() == text.toLowerCase();
    });
    if (_.get(found, 'bn')) {
        return found[this.state.selectedLanguage];
    }
    return text;
}
Store.changeLanguage = function(lan='en') {
    localStorage.setItem('lang', lan)
    this.state.selectedLanguage = localStorage.getItem('lang')
}
// console.log(Store);

export default Store